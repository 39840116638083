.feature-work-container {
  width: 98%;
  margin: 0 auto;
}
.featured-title {
  text-align: center;
  font-size: 20px;
  padding: 30px;
  margin-bottom: 20px;
}
.featured-first-row-video {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 100vh; */
}

.featured-video-item {
  width: calc((100% / 3) - 1%);
}
.featured-video-item video {
  width: 100%;
}
.feature-second-row {
  display: flex;
  justify-content: space-between;
  /* height: 100vh; */
}
.featured-video-item-second-row {
  width: calc((100% / 2) - 1%);
}
.featured-video-item-second-row video {
  width: 100%;
}
.video-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
}
/* @media screen and (max-width: 2000px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 82vh;
  }
}
@media screen and (max-width: 1700px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 72vh;
  }
}
@media screen and (max-width: 1500px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 60vh;
  }
}
@media screen and (max-width: 1200px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 45vh;
  }
}
@media screen and (max-width: 900px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 30vh;
  }
}
@media screen and (max-width: 700px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 20vh;
  }
} */
/* @media screen and (max-width: 1200px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 80vh;
  }
}
@media screen and (max-width: 1000px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 60vh;
  }
}
@media screen and (max-width: 800px) {
  .featured-first-row-video,
  .feature-second-row {
    height: 45vh;
  }
} */
@media screen and (max-width: 500px) {
  .video-container {
    margin-bottom: unset;
    gap: 20px;
  }
  .featured-first-row-video {
    flex-direction: column;
    gap: 20px;
    height: unset;
  }
  .featured-video-item {
    width: 100%;
  }
  .feature-second-row {
    flex-direction: column;
    gap: 20px;
    height: unset;
  }
  .featured-video-item-second-row {
    width: 100%;
  }
}
