.ai-slider {
  overflow: hidden;
}
.ai-title {
  text-align: center;
  letter-spacing: 1.87px;
  font-family: "Montserrat-Light", sans-serif;
}
.ai-image-container {
  padding: 20px;
}
.ai-image-container img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
:focus-visible {
  outline: none !important;
}
.ai-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .ai-mobile {
    display: block;
  }
  .ai-desktop {
    display: none;
  }
  .ai-title {
    font-size: 14px;
    padding: 20px;
  }
  .mobile-ai-image-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .mobile-image {
    width: 100%;
  }
  .mobile-image img {
    width: 100%;
  }
}
