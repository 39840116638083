.projectDetails-slider {
  overflow: hidden;
  height: 70vh;
}
.images-projectDetails-container {
  display: flex !important;
  justify-content: space-between;
  height: 70vh;
}
.projectDetails-bigimage-container {
  width: 34%;
}
.projectDetails-bigimage-container img {
  width: 100%;
  height: 100%;
}
.projectDetails-small-images {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projectDetails-smallimage-container {
  width: 100%;
  height: 49%;
}
.projectDetails-smallimage-container img {
  width: 100%;
  height: 100%;
}
.ProjectDetails-title {
  padding: 30px;
  text-align: center;
  letter-spacing: 1.87px;
  font-family: "Montserrat-Light", sans-serif;
}
.projectsDetails-kit-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.projectDetails-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.odd-row {
  flex-direction: row-reverse;
}

.even-row {
  flex-direction: row;
}
.projectDetails-column {
  width: 50%;
  height: 400px;
}
.projectDetails-column img,
.projectDetails-column video {
  width: 100%;
  height: 100%;
}
.projectDetails-Fullscreen {
  margin-top: 30px;
}
.projectDetails-imageContainer {
  width: 100%;
  height: 90vh;
}
.projectDetails-imageContainer img {
  width: 100%;
  height: 100%;
}
.projectDetails-mobile {
  display: none;
}
.ProjectDetails-title-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .productDetails-slider-desktop {
    display: none;
  }
  .ProjectDetails-title-mobile {
    display: block;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    font-family: "Montserrat-Light", sans-serif;
  }
  .ProjectDetails-title {
    display: none;
  }
  .projectDetails-slider {
    height: unset;
  }
  .projectDetails-mobile {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .mobile-image-projectDetails {
    width: 100%;
  }
  .mobile-image-projectDetails img {
    width: 100%;
  }
  .projectDetails-row {
    flex-direction: column;
    gap: 30px;
  }
  .projectDetails-column {
    width: 100%;
    height: unset;
  }
}
