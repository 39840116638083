.mac-slider {
  height: 70vh;
  overflow: hidden;
}
.ai-video-container {
  padding: 20px;
}
.ai-video-container video {
  width: 100%;
}
.mac-footer {
  height: 40vh;
  width: 100%;
  margin-top: 50px;
}
.mac-footer video {
  height: 100%;
  width: 100%;
}
.mac-small-description {
  text-align: center;
  margin-top: 50px;
}
.mac-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mac-icon {
  width: calc(100% / 7.5);
}
.mac-icon img {
  width: 100%;
}
.mac-mobile-version {
  display: none;
}
@media screen and (max-width: 500px) {
  .mac-desktop-version {
    display: none;
  }
  .mac-mobile-version {
    display: block;
  }
  .mac-mobile-videos {
    display: flex;
    flex-direction: column;
  }
  .ai-video-container {
    width: 100%;
    padding: 0;
  }
  .ai-video-container video {
    width: 100%;
  }
  .mac-icon {
    width: 30%;
  }
  .mac-icons {
    flex-wrap: wrap;
    justify-content: center;
  }
}
