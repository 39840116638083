.workAlongSide-title {
  display: flex;
  justify-content: center;
}

.brandName-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #b7b7b7;
  font-size: 20px;
  gap: 5px;
}
.workAlong-title {
  font-weight: bolder;
  font-size: 40px;
}

@media screen and (max-width: 500px) {
  .workAlongSide-container {
    margin-top: 70px;
  }
  .workAlong-title {
    font-size: 28px;
  }
  .brandName-container div {
    text-align: center;
  }
  .brandName-container {
    margin-top: 30px;
  }
}
