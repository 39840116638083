.nature-first-row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 125vh;
  position: relative;
  top: -107px;
  z-index: -99;
}
.top-image-nature {
  top: 100px;
  left: 25px;
  position: absolute;
  width: 25%;
}
.top-image-nature img {
  width: 100%;
}
.text-image-nature {
  position: relative;
  bottom: 70px;
}
.bottom-text-image-nature {
  width: 90%;

  position: absolute;
  bottom: 0px;
  left: 100px;
}
.bottom-text-image-nature .right-logo-nature {
  width: 30%;
}
.right-logo-nature img {
  width: 100%;
}
.bottom-text-image-nature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-text-image-nature p {
  font-family: "Montserrat-Medium", sans-serif;
  letter-spacing: 1.87px;
}
.second-row-nature {
  background-color: #f5f4ed;
  height: 100vh;
  margin-top: -107px;
}
.logo-image-nature {
  width: 90%;
  margin: 0 auto;
}
.logo-image-nature img {
  width: 100%;
}
.iphone-image-nature {
  width: 20%;

  margin: 0 auto;
  position: relative;
  bottom: 300px;
}
.iphone-image-nature img {
  width: 100%;
}
.iphone-image-nature video {
  width: 80%;
  top: 44px;
  /* border-radius: 36px; */
  position: absolute;
  right: 28px;
}
.mobile-nature {
  display: none;
}
@media screen and (max-width: 500px) {
  .desktop-nature {
    display: none;
  }
  .mobile-nature {
    display: block;
  }
  .nature-mobile-desc {
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat-Medium", sans-serif;
  }
  .nature-mobile-firstrow {
    display: flex;
    justify-content: center;
  }
  .nature-image-container {
    width: 60%;
  }
  .nature-image-container img {
    width: 100%;
  }
  .text-image-nature-mobile {
    margin-top: -40px;
  }
  .nature-second-row {
    height: 100vh;
    overflow: hidden;
  }
  .nature-second-row img {
    position: relative;
    right: 275px;
    width: 230%;
    height: 100%;
  }
  .nature-third-row {
    height: 110vh;
    background-color: #f5f4ed;
    overflow: hidden;
  }
  .first-image-nature-third-row {
    overflow: hidden;
  }
  .first-image-nature-third-row img {
    width: 200%;
    position: relative;
    right: 200px;
  }
  .nature-iphone-frame {
    width: 90%;
    position: relative;
    top: -100px;
    left: 25px;
  }
  .nature-iphone-frame img {
    width: 100%;
  }
  .nature-iphone-frame video {
    position: absolute;
    width: 75%;
    right: 48px;
    top: 77px;
  }
}
