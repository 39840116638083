@import url("../../../variables.css");

.projectIcon-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-image {
  width: 100%;
}
.projectIcon-container .pi-search {
  margin-left: 0;
}
.projectIcon-container .title-of-table {
  font-weight: 600;
}

.projectIcon-container .p-button.p-button-success,
.projectIcon-container .p-buttonset.p-button-success > .p-button,
.projectIcon-container .p-splitbutton.p-button-success > .p-button,
.projectIcon-container .p-fileupload-choose.p-button-success {
  background-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
  border: none;
}

.projectIcon-container
  .p-datatable
  .p-sortable-column.p-highlight
  .p-sortable-column-icon {
  color: var(--purple-primary-color);
}

.projectIcon-container button {
  border-radius: 10px;
  padding: 12px 18px;
  font-size: smaller;
}

.projectIcon-container .p-datatable .p-datatable-header {
  padding: 5px !important;
  background-color: var(--light-color-background);
  border: none;
  color: #160e3c;
  border-radius: 10px 10px 0 0;
}

.projectIcon-container .p-datatable .p-datatable-thead > tr > th {
  background: var(--light-color-background);
  color: #160e3c;
  padding: 1rem 1rem 22px 1rem;
}

.projectIcon-container
  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled:hover {
  border: 1px solid var(--grey-light-color-borders);
}

.projectIcon-container .p-dropdown-items {
  padding: 0;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid var(--grey-light-color-borders);
  box-shadow: none !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--light-color-background);
  color: #160e3c;
}

.projectIcon-container ul.p-dropdown-items {
  padding-left: 0 !important;
}

.projectIcon-container .p-datatable .p-datatable-tbody > tr {
  border-bottom: 1px solid black !important;
}

.projectIcon-container .p-toolbar {
  background-color: white;
  border: none;
}

.projectIcon-container .p-button.p-button-help,
.projectIcon-container .p-buttonset.p-button-help > .p-button,
.projectIcon-container .p-splitbutton.p-button-help > .p-button,
.projectIcon-container .p-fileupload-choose.p-button-help {
  color: white;
  background: var(--purple-primary-color);
  border: none;
}

.projectIcon-container .p-button.p-button-success:hover {
  background-color: var(--light-color-hover);
}

.projectIcon-container .p-button.p-button-help:hover {
  background-color: var(--light-color-hover);
}

.projectIcon-container .p-button:focus {
  box-shadow: none !important;
}

.projectIcon-container .p-button.p-button-outlined {
  color: var(--purple-primary-color);
}

.projectIcon-container
  .p-button.p-component.p-button-icon-only.p-button-outlined.p-button-rounded:hover {
  background: #f0eeff;
}

.projectIcon-container .p-button.p-button-outlined.p-button-danger {
  color: #dc3545;
}

.p-button.p-component.p-button-danger:hover{
    background-color: #ffb2bc;
    border-color: #ffb2bc;
}

.projectIcon-container
  .p-button.p-component.p-button-icon-only.p-button-outlined.p-button-rounded.p-button-danger:hover {
  background: #fff7f8;
}

.projectIcon-container .p-button {
  transition: none;
}

.projectIcon-container
  .p-paginator
  .p-paginator-pages
  .p-paginator-page.p-highlight {
  background: var(--light-color-background);
  border-color: none;
  color: #160e3c;
}

.projectIcon-container .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e5e8f8;
  color: #000;
}

.projectIcon-container .p-checkbox-box {
  outline: none !important;
  box-shadow: none !important;
}

.projectIcon-container
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover,
.projectIcon-container
  .p-checkbox
  .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.projectIcon-container .p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
  outline: none !important;
  box-shadow: none !important;
}

.projectIcon-container .p-checkbox .p-checkbox-box {
  transition: none;
  outline: none !important;
  box-shadow: none !important;
}

.projectIcon-container
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box:hover {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.projectIcon-container
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box:hover {
  border: 2px solid var(--grey-light-color-borders);
  outline: none !important;
  box-shadow: none !important;
}

.projectIcon-container .dropdown-menu-dark {
  color: white;
  background-color: var(--purple-primary-color);
  border-color: none;
}

.projectIcon-container .dropdown-menu-dark .dropdown-item {
  color: white;
}
.projectIcon-container .dropdown-menu-dark .dropdown-item:focus,
.projectIcon-container .dropdown-menu-dark .dropdown-item:hover {
  color: white;
  cursor: pointer;
  background-color: transparent;
}

.projectIcon-container a:hover {
  color: #160e3c;
}

.projectIcon-container a {
  color: #160e3c;
}

.projectIcon-container .p-inputtext:enabled:hover {
  border-color: white;
}

.projectIcon-container .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: white;
}

.projectIcon-container .p-inputtext {
  transition: none;
  border-color: white;
}
