@import url("./variables.css");
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../public/fonts/Montserrat-Medium.ttf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../public/fonts/Montserrat-Light.ttf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("../public/fonts/Montserrat-Italic.ttf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../public/fonts/Montserrat-Regular.ttf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("../public/fonts/Montserrat-MediumItalic.ttf") format("truetype"); /* Adjust the path to your font file */
}
* {
  box-sizing: border-box;
  font-family: "Montserrat-Regular", sans-serif;
}
body {
  line-height: unset;
  cursor: url("../public/Logo/icon.svg"), auto;
  cursor: -webkit-image-set(url("../public/Logo/icon.svg")), auto;
}
a,
.right-arrow,
.arrow-left {
  cursor: url("../public/Logo/icon.svg"), pointer;
  cursor: -webkit-image-set(url("../public/Logo/icon.svg")), pointer;
}
.arrows-projects {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .arrows-projects {
    display: none;
  }
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: var(--purple-primary-color);
}

.p-checkbox .p-checkbox-box {
  transition: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--grey-light-color-borders);
  background: var(--grey-light-color-borders);
  color: white;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--grey-light-color-borders);
  background: var(--grey-light-color-borders);
  color: white;
}
.p-input-filled
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: var(--grey-light-color-borders);
}
.p-checkbox {
  width: 22px;
  height: 22px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid var(--grey-light-color-borders);
  background: white;
  width: 22px;
  height: 22px;
  color: #4b5563;
  border-radius: 6px;
  transition: none;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: white;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 14px;
  height: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--grey-light-color-borders);
  background: var(--grey-light-color-borders);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--grey-light-color-borders);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--grey-light-color-borders);
}

.p-dialog-content {
  padding: 20px !important;
}

.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #e24c4c;
}
.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f3f4f6;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--grey-light-color-borders);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f3f4f6;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--grey-light-color-borders);
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  transition: none !important;
  border-color: var(--grey-light-color-borders);
}
.p-dropdown {
  box-shadow: none !important;
  transition: none !important;
}
ul {
  padding-left: 0 !important;
}
/* .p-dropdown-item {
  text-align: center;
} */
.p-button.p-component.p-fileupload-choose:hover,
.p-button:not(:disabled):hover {
  background: var(--light-color-hover);
  border-color: none !important;
}
.p-button.p-component.p-fileupload-choose {
  color: white;
  background-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
  border: none;
  font-size: smaller;
  transition: none !important;
  border-radius: 10px;
}
.p-button:focus {
  box-shadow: none !important;
  transition: none !important;
}

.p-dialog-footer .p-button.p-component.p-button-outlined {
  color: var(--purple-primary-color);
  font-size: smaller;
  transition: none !important;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid var(--purple-primary-color);
}
.p-dialog-footer .p-button.p-component.p-button-outlined:hover {
  background-color: var(--light-color-background);
}

.p-dialog-footer .p-button.p-component {
  background-color: var(--purple-primary-color);
  font-size: smaller;
  transition: none !important;
  border-radius: 10px;
  border: none;
}
.p-dialog-footer .p-button.p-component:hover {
  background-color: var(--light-color-hover);
}
.p-inputtext:enabled:hover {
  border-color: var(--grey-light-color-borders);
}
.p-inputtext {
  transition: none !important;
}
.p-inputtext:enabled:focus {
  border-color: var(--grey-light-color-borders);
}

.dropdown-menu-dark {
  background-color: var(--purple-primary-color);
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  background-color: var(--purple-primary-color);
}
