.haluFirstImage {
  /* height: 100vh; */
  width: 100%;
  position: relative;
  top: -106px;
  z-index: -99;
}
.haluFirstImage img {
  width: 100%;
}
.project-description {
  position: absolute;
  letter-spacing: 1.87px;
  bottom: 15%;
  color: white;
  z-index: 99;
  width: 60%;
  left: 15%;
  font-size: 1rem;
  font-family: "Montserrat-Medium", sans-serif;
  font-weight: normal;
}
.secondrow-halu {
  width: 100%;
  display: flex;
  margin-top: -80px;
  height: 400px;
}
.secondrow-halu .first-col-image {
  width: 50%;
  height: 100%;
}
.secondrow-halu .first-col-image img {
  width: 100%;
  height: 100%;
}
.secondrow-halu .second-col-video {
  width: 50%;
}
.second-col-video video {
  width: 100%;
  height: 100%;
}
.dynamic-rows {
  margin-top: 30px;
  width: 100%;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
}
.dynamic-row {
  width: 50%;
  margin-bottom: 30px;
}
.dynamic-row img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .halu-container {
    display: flex;
    flex-direction: column;
  }
  .haluFirstImage {
    position: relative;
    top: unset;
    height: 100vh;
    overflow: hidden;
  }
  .project-description {
    top: 30px;
    width: 90%;
    font-size: 18px;
    left: 7%;
    color: black;
    font-weight: bolder;
  }
  .haluFirstImage img {
    height: 100%;
    width: unset;
    position: relative;
    right: 600px;
  }
  .secondrow-halu {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
    height: unset;
  }
  .secondrow-halu .first-col-image,
  .secondrow-halu .second-col-video {
    width: 100%;
  }
  .dynamic-rows {
    margin-top: 20px;
  }
  .dynamic-row {
    width: 100%;
  }
}
