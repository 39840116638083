.pagenotFound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.logo-text {
  width: 50%;
  margin: 0 auto;
}

.text-404 h1 {
  text-align: center;
  font-size: 80px;
  font-weight: bold;
}
.text-404 p {
  text-align: center;
}
