.slider-container {
  height: 100vh;
}

.slider-container .slick-slider,
.slider-container .slick-slider .slick-list,
.slider-container .slick-slider .slick-list .slick-track {
  height: 100%;
}

.card {
  position: relative;
  border: 5px solid white;
}

.card img {
  width: 100%;
  height: 100%;
}

.card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.card:hover .overlay {
  opacity: 1;
}

.card .overlay h4,
.card .overlay p {
  margin: 0;
  color: black;
}

.card .overlay h4 {
  /* cursor: pointer; */
  text-transform: uppercase;
  font-weight: bold;
}

.card .overlay p {
  text-transform: uppercase;
}

.slider-home-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .slider-container {
    display: none;
  }
  .slider-home-mobile {
    display: flex;
    flex-direction: column;
  }
  .slider-image-mobile-container {
    width: 100%;
  }
  .slider-image-mobile-container img {
    width: 100%;
  }
}
