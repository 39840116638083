@import url("../../../variables.css");

.signin-container {
  background-image: url("../../assets/background.jpg");
  background-repeat: round;
  width: 100%;
  height: 100vh;
}
.backgroundoverlay {
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 30%;
  padding: 1%;
  padding-bottom: 5%;
  border-radius: 10px;
  background-color: white;
  opacity: 0.95;
}
.user-icon {
  text-align: center;
}
.login-form form {
  width: 80%;
  margin: 0 auto;
}
.login-form form #email,
#password .p-password-input {
  width: 100%;
  background-color: white !important;
  border: 2px solid black;
  border-radius: 10px;
}
.login-form .p-button {
  width: 100%;
  margin-top: 10px;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  background-color: var(--purple-primary-color) !important;
}
.login-form .p-button:hover {
  background-color: var(--light-color-hover) !important;
}
#email::placeholder,
#password .p-password-input::placeholder {
  color: #1d1717;
}
.apiErrorMessage {
  color: #e24c4c;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 0px;
}
.login-form form #email,
#password .p-password-input,
.login-form .p-button {
  padding: 18px;
  margin: 8px;
}

@media (max-width: 1200px) {
  .login-form {
    width: 55%;
  }
  .login-form form #email,
  #password .p-password-input,
  .login-form .p-button {
    padding: 25px;
    margin: 8px;
  }
}
@media (max-width: 1000px) {
  .login-form {
    width: 70%;
  }
  .signin-container {
    background-image: url("../../assets/backgroundphone.png");
  }
  .login-form form #email,
  #password .p-password-input {
    border: 1px solid black;
  }
}
@media (max-width: 650px) {
  .login-form {
    width: 70%;
  }
}
@media (max-width: 490px) {
  .login-form {
    width: 80%;
  }
  .login-form form #email,
  #password .p-password-input,
  .login-form .p-button {
    padding: 18px;
    margin: 8px;
  }
}

.login-form .p-error {
  margin: 8px;
}
