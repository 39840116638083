.smellycat-first-row {
  background-color: #e8e8e8;
  height: 100vh;
  position: relative;
  top: -107px;
  z-index: -99;
}
.cat-image {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cat-image img {
  padding-top: 100px;
  width: 55%;
}
.smelly-description {
  padding-top: 50px;
  width: 75%;
  margin: 0 auto;
  letter-spacing: 1.87px;
  font-family: "Montserrat-Medium", sans-serif;
}
.smellycat-second-row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 110vh;
  margin-top: -107px;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.smellycat-logo-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  padding-top: 70px;
}
.smellycat-logo-image img {
  width: 27%;
}
.smellycat-multiple-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  padding-top: 70px;
}
.smellycat-multiple-image img {
  width: 25%;
}
.smelly-desc-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .smellycat-first-row {
    position: unset;
  }
  .cat-image img {
    padding: 0;
  }
  .smelly-description {
    display: none;
  }
  .smelly-desc-mobile {
    display: block;
    font-family: "Montserrat-Medium", sans-serif;
    text-align: center;
    padding-top: 50px;
  }
  .smellycat-logo-image img {
    width: 100%;
  }
  .smellycat-logo-image {
    padding: unset;
    height: unset;
    margin-top: -110px;
  }
  .smellycat-multiple-image img {
    width: 90%;
  }
  .smellycat-multiple-image {
    padding: unset;
    height: unset;
  }
  .smellycat-second-row {
    height: 90vh;
  }
  .cat-image img {
    width: 90%;
  }
}
