.loading-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.logoLoading {
  width: 40%;
}
.logoLoading img {
  width: 100%;
}
.spinnerLoading {
  text-align: center;
}
