@import url("../../variables.css");

.nav-link.active {
  background-color: var(--purple-primary-color) !important;
  color: white;
}

.list-group-item-action:hover,
a.nav-link.text-white.list-group-item-action:focus,
a.nav-link.text-white.list-group-item-action:active {
  background-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
  color: white;
}

.list-group-item-action {
  font-size: 18px;
  margin-bottom: 6px;
}
