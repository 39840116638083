.services-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}
.servicesContent p {
  font-family: "Montserrat-Medium";
  /* font-weight: bold; */
  margin: 0;
  padding: 0;
  font-size: 35px;
  letter-spacing: 3px;
}
.socialMedia {
  padding-left: 50px !important;
}
.websitedesign {
  padding-left: 140px !important;
}
.artdirection {
  margin-left: -55px !important;
}
.paidmedia {
  margin-left: -202px !important;
}
.alotmore {
  text-align: right;
  font-size: 20px !important;
}
@media screen and (max-width: 1060px) {
  .servicesContent {
    margin-left: 150px;
  }
}
@media screen and (max-width: 900px) {
  .branding,
  .socialMedia,
  .artdirection,
  .paidmedia,
  .websitedesign {
    font-size: 24px !important;
  }
  .alotmore {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 700px) {
  .services-container {
    height: 65vh;
  }
  .servicesContent p {
    font-family: "Montserrat-Medium" !important;
  }
  .branding,
  .socialMedia,
  .websitedesign,
  .paidmedia,
  .artdirection {
    padding: unset !important;
    margin: unset !important;
    font-size: 18px !important;
    font-weight: unset !important;
  }

  .servicesContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: unset !important;
  }
  .alotmore {
    margin-top: 30px !important;
    font-size: 9px !important;
    text-align: unset;
    letter-spacing: unset !important;
    font-weight: unset !important;
  }
}
