.folie-first-row {
  height: 140vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.folie-link {
  color: black;
  text-decoration: none;
  font-size: 20px;
  padding-top: 130px;
  font-family: "Montserrat-Light", sans-serif;
  cursor: pointer;
  display: inline-block;
  margin-left: 150px;
}
.folie-second-row {
  margin-top: 50px;
}
.folie-second-row p,
.folie-hover {
  font-family: "Montserrat-Light", sans-serif;
}
.folie-small-desc {
  font-size: 20px;
  text-align: center;
  margin-bottom: unset;
}
.folie-videos {
  margin-top: 30px;
  gap: 30px;
  display: flex;
  justify-content: center;
}
.folie-video-container {
  width: 30%;
}
.folie-video-container video {
  width: 100%;
}
.folie-hover {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}
.folie-third-row {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.folie-image-row {
  height: 110vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.folie-image-desc {
  color: white;
  text-align: center;
  padding-top: 50px;
  font-size: 24px;
  font-weight: bold;
}
.folie-mobile-version {
  display: none;
}

@media screen and (max-width: 500px) {
  .folie-desktop-version {
    display: none;
  }
  .folie-mobile-version {
    display: block;
  }
  .folie-first-row-mobile {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .folie-first-row-mobile img {
    height: 100%;
    position: relative;
    right: 200px;
  }
  .folie-small-desc {
    font-size: 14px;
    margin-bottom: 30px;
    font-family: "Montserrat-Light", sans-serif;
  }
  .folie-videos {
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
  .folie-video-container {
    width: 80%;
  }
  .folie-mobile-third-row {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .folie-mobile-image-row {
    width: 100%;
  }
  .folie-mobile-image-row img {
    width: 100%;
  }
}
