.baladi-slider {
  overflow: hidden;
  margin-bottom: 30px;
  height: 70vh;
}
.baladi-small-desc {
  text-align: center;
  font-size: 20px;
  margin-bottom: unset;
  font-family: "Montserrat-Light", sans-serif;
}
.images-baladi-container {
  display: flex !important;
  gap: 5%;
  height: 70vh;
  justify-content: center;
}
.baladi-bigimage-container {
  width: 55%;
}
.baladi-bigimage-container img {
  width: 100%;
  height: 100%;
}
.baladi-small-images {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.baladi-smallimage-container {
  width: 100%;
  height: 48%;
}
.baladi-smallimage-container img {
  width: 100%;
  height: 100%;
}
.berbara-mobile-version {
  display: none;
}
@media screen and (max-width: 500px) {
  .desktop-berbara-version {
    display: none;
  }
  .berbara-mobile-version {
    display: block;
  }
  .berbara-title {
    font-size: 16px;
    text-align: center;
    padding: 10px;
  }
  .berbara-desc {
    font-size: 13px;
    text-align: center;
    padding: 10px;
    font-weight: bolder;
    font-family: "Montserrat-Light", sans-serif;
  }
  .mobile-berbara-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .berbara-mobile-item {
    width: 100%;
  }
  .berbara-mobile-item img {
    width: 100%;
  }
}
