.marianne-first-row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 105vh;
  position: relative;
  top: -107px;
  z-index: -99;
  display: flex;
  flex-wrap: wrap;
}
.logo-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.image-content {
  width: 50%;
}
.image-content img {
  width: 100%;
}
.marianne-desc {
  margin-top: -80px;
  margin-left: 140px;
  letter-spacing: 1.87px;

  font-family: "Montserrat-Medium", sans-serif;
}
.mariane-second-row {
  display: flex;
}
.left-image {
  width: 60%;
}
.left-image img {
  width: 100%;
}
.right-text {
  width: 40%;
  padding: 0 30px;
}
.right-text p {
  font-family: "Montserrat-Light", sans-serif;
}
.marianne-mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .marianne-desktop {
    display: none;
  }
  .marianne-mobile {
    display: block;
  }
  .marianne-first-row {
    position: unset;
    height: 100vh;
    display: block;
  }
  .marianne-desc {
    margin: unset;
    text-align: center;
    font-size: 14px;
    padding: 20px;
  }
  .image-content-mobile {
    width: 100%;
    overflow: hidden;
  }
  .image-content-mobile img {
    position: relative;
    right: 127px;
    width: 160%;
  }
  .marianne-secondrow-image {
    width: 100%;
  }
  .mariane-mobile-secondrow {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .marianne-secondrow-image img {
    width: 100%;
  }
  .mariane-mobile-text {
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
  }
  .mariane-mobile-text p {
    font-family: "Montserrat-Light", sans-serif;
  }
  .marianne-mobile .dynamic-row,
  .marianne-mobile .dynamic-rows {
    margin-bottom: unset;
    margin-top: unset;
  }
}
