.brandicons-container {
  padding: 30px;
  border-top: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  margin-top: 100px;
}
.brand-icons {
  display: flex;
  width: 85%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.icon-image img {
  width: 100%;
}
.icon-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 80px;
}
.icon-row {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 50px;
}
.brand-icons-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .brand-icons {
    display: none;
  }
  .brand-icons-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .icon-image-mobile {
    width: 40%;
  }
  .icon-image-mobile img {
    width: 100%;
  }
}
