.lumiFirstImage {
  width: 100%;
  position: relative;
  top: -106px;
  z-index: -99;
}
.lumiFirstImage img {
  width: 100%;
}
.logo-lumi {
  width: 50%;
  position: absolute;
  bottom: 100px;
  margin-left: 50px;
}
.lumiproject-description {
  color: white;
  position: absolute;
  bottom: 150px;
  margin-left: 97px;
  letter-spacing: 1.87px;
  font-family: "Montserrat-Medium", sans-serif;
}
.lumi-second-row {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 140vh;
  margin-top: -106px;
}
.lumi-bottle-image {
  width: 25%;
  padding-top: 50px;
  margin-left: 100px;
}
.lumi-bottle-image img {
  width: 100%;
}
.lumi-bottle-image-desc {
  color: white;
  margin-top: 30px;
  font-family: "Montserrat-MediumItalic", sans-serif;
}
.lumi-secondrow-desc {
  color: white;
  width: 40%;
  padding-top: 50px;
  font-family: "Montserrat-MediumItalic", sans-serif;
}
.lumi-secondrow-name {
  color: white;
  font-size: 28px;
  padding-top: 30px;
  font-family: "Montserrat-MediumItalic", sans-serif;
}
.lumi-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .lumi-desktop {
    display: none;
  }
  .lumi-mobile {
    display: block;
  }
  .lumiFirstImage-mobile {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .lumiFirstImage-mobile img {
    position: relative;
    right: 250px;
    height: 100%;
  }
  .logo-lumi-mobile {
    position: absolute;
    width: 250px;
    z-index: 99;
    top: 300px;
    left: 300px;
  }
  .logo-lumi-mobile img {
    width: 145%;
  }
  .lumi-bottle-image-mobile {
    width: 100%;
    margin-top: 30px;
  }
  .lumi-bottle-image-mobile img {
    width: 100%;
  }
  .lumi-second-row-mobile {
    height: 100vh;
    margin-top: 30px;
    position: relative;
  }
  .lumi-secondrow-desc-mobile {
    font-family: "Montserrat-MediumItalic", sans-serif;
  }
  .lum-mobile-image {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .lum-mobile-image img {
    width: 125%;
    height: 100%;
    position: relative;
    right: 100px;
  }
  .lumi-mobile-desc {
    top: 80px;
    left: 30px;
    font-size: 14px;
    width: 70%;
    position: absolute;
    color: white;
  }
  .lumi-secondrow-name-mobile {
    font-size: 18px;
    font-family: "Montserrat-MediumItalic", sans-serif;
  }
}
