.nav-container {
  width: 95%;
  margin: 0 auto;
  height: 100px;
}
@keyframes slideInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translateY(-100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}
.navbarMainContainer {
  transition: all 0.4s;
}
.navbarMainContainer.sticky {
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideInDown 0.6s forwards;
  -webkit-animation: slideInDown 0.6s forwards;
}

.leftside-links,
.rightside-links {
  width: 40%;
}

.nav-container a {
  font-size: 20px;
  text-decoration: none;
  color: black;
}

.nav-container a:hover {
  font-style: italic;
  font-weight: 600;
}
.logo {
  width: 150px;
}
.logo img {
  width: 100%;
}
.navbar-mobile-version {
  display: none;
}
@media screen and (max-width: 950px) {
  .menubar-mobile.p-sidebar .p-sidebar-header .p-sidebar-close,
  .menubar-mobile.p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 6rem !important;
    height: 6rem !important;
  }
  .menubar-mobile .p-icon {
    width: 100%;
    height: 100%;
  }
  .navbar-container-mobile-desktop {
    background-color: #fff;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
  .nav-container {
    display: none !important;
  }
  .navbar-mobile-version {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-menu-bar {
    padding: 20px;
  }
  .right-menu-bar .p-button:not(:disabled):hover {
    background: unset;
    border: unset;
  }
  .right-menu-bar .p-button {
    background: unset;
    border: unset;
  }
  .right-menu-bar .p-button-icon {
    color: black;
    font-size: 30px;
  }
  .menubar-mobile .p-link:focus-visible {
    box-shadow: unset !important;
  }
  .menubar-mobile.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .menubar-mobile.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    background: unset !important;
  }

  .menubar-mobile {
    width: 100% !important;
  }
  .menubar-mobile svg {
    width: 10rem;
    height: 10rem;
    color: white;
  }
  .menubar-mobile .p-sidebar-content,
  .menubar-mobile .p-sidebar-header {
    background-color: #5dba47;
  }
  .right-side-bar {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }
  .right-side-bar a {
    font-family: "Montserrat-MediumItalic", sans-serif;
    font-size: 30px;
    color: white;
    text-decoration: none;
  }
}
